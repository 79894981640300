<template>
    <div class="page">
        <div class="authStatus" v-if="authSuccess">
            <div class="success"></div>
            <p>授权成功</p>
        </div>
        <div class="authStatus" v-else>
            <div class="fail"></div>
            <p>授权失败</p>
        </div>
        <div class="btns" v-if="isMobile">
            <div class="btn" @click="handleClickNativePath('lpmerchant://route/store')">门店管理</div>
            <div class="backHome" @click="handleClickNativePath('lpmerchant://route/pop/root')">返回首页</div>
        </div>
    </div>
</template>
<script>
import http from '@/http';

export default {
    data(){
        return {
            authSuccess: false,
            isMobile: false,
        };
    },
    created() {
        if(this.$route.query.code){
            this.authSuccess = true;
            this.postAuthData();
        }
        this.isMobile = this.check();
        
    },
    mounted() {
        document.title = '闪送授权';
    },
    methods: {
        check(){
            let userAgent = window.navigator.userAgent.toLocaleLowerCase();
            let agents = ['iphone', 'android', 'ipad', 'ipod', 'windows phone', 'symbianos'];
            let flag = false;
            for(let i=0; i<agents.length; i++){
                if(userAgent.indexOf(agents[i]) > 0){
                    flag = true;
                    break;
                }
            };
            this.isMobile = flag;
            return flag;
        },
        postAuthData(){
            http.post('/?cmd=com.xqxc.api.pigear.biz.platform.ShansongMTokenService.getTokenByCode&group=earH5', {
                code: this.$route.query.code,
                merchantId: this.$route.query.shopId,
                state:this.$route.query.state,
                isAllStoreAuth: this.$route.query.isAllStoreAuth == 'true',
                thirdStoreId:this.$route.query.thirdStoreId,
                storeId:this.$route.query.storeId,
            }).then(res => {
                this.result = res.result;
            })
        },
        handleClickNativePath(path) {
            window.location.href = path
        }
    },
}
</script>
<style lang="less" scoped>
.page { 
    padding: .3rem;
    font-size: .28rem;
    line-height: 1.5;

    .btn{
        width: 6.9rem;
        height: .88rem;
        border-radius: .1rem;
        background: #00ABEB;
        color: #FFF;
        font-size: .32rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .2rem auto;
    }
    .backHome{
        width: 6.9rem;
        height: .88rem;
        border-radius: .1rem;
        background: #2dc7ff;
        color: #FFF;
        font-size: .32rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .2rem auto;
    }
}
.btns{
    position: fixed;
    bottom: 0;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.authStatus{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .success, .fail{
        width: 2rem;
        height: 2rem;
        overflow: hidden;
        border-radius: 50%;
        margin-top: 1rem;
    }
    .success{
        background: url('../../assets/images/icon-success.png') no-repeat center;
        background-size: cover;
    }
    .fail{
        background: url('../../assets/images/icon-fail.png') no-repeat center;
        background-size: cover;
    }
    p{
        margin-top: 10px;
        font-size: .34rem;
        text-align: center;
    }
}
    
</style>